var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-5 text-xs"},[_c('div',{staticClass:"mb-4"},[_c('button',{staticClass:"flex items-center text-blue-500",on:{"click":function($event){return _vm.$router.push('/tiket')}}},[_c('i',{staticClass:"fas fa-chevron-left w-6 h-6 mb-2"}),_c('span',{staticClass:"ml-2 mb-5"},[_vm._v("Kembali")])])]),_c('div',{staticClass:"rounded-2xl bg-white p-5 py-13 shadow-md"},[_vm._m(0),_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{staticClass:"w-full",on:{"submit":function($event){$event.preventDefault();return _vm.updateTicket($event)}}},[_c('div',{staticClass:"grid grid-cols-1 md:grid-cols-3 gap-4"},[_c('div',{staticClass:"mb-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"block mb-2 text-sm font-medium",attrs:{"for":"device_id"}},[_vm._v("Device")]),_c('div',{staticClass:"relative"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.ticketData.device_id),expression:"ticketData.device_id"}],staticClass:"block border border-gray-300 w-full p-3 rounded text-sm bg-white text-gray-700",attrs:{"id":"device_id","disabled":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.ticketData, "device_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.devices),function(device){return _c('option',{key:device.id,domProps:{"value":device.id}},[_vm._v(" "+_vm._s(device.code)+" - "+_vm._s(device.name)+" ")])}),0)]),_c('span',{staticClass:"text-red-600 text-xs"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"mb-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"block mb-2 text-sm font-medium",attrs:{"for":"customer_id"}},[_vm._v("Pelanggan*")]),_c('div',{staticClass:"relative"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.ticketData.customer_id),expression:"ticketData.customer_id"}],staticClass:"block border border-gray-300 w-full p-3 rounded text-sm bg-white text-gray-700",attrs:{"id":"customer_id"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.ticketData, "customer_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.customers),function(customer){return _c('option',{key:customer.id,domProps:{"value":customer.id}},[_vm._v(" "+_vm._s(customer.company_name)+" ")])}),0)]),_c('span',{staticClass:"text-red-600 text-xs"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"mb-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"block mb-2 text-sm font-medium",attrs:{"for":"name"}},[_vm._v("Judul Keluhan*")]),_c('div',{staticClass:"relative"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ticketData.name),expression:"ticketData.name"}],staticClass:"block border border-gray-300 w-full p-3 rounded text-sm bg-white text-gray-700",attrs:{"id":"name","type":"text"},domProps:{"value":(_vm.ticketData.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ticketData, "name", $event.target.value)}}})]),_c('span',{staticClass:"text-red-600 text-xs"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"col-span-1 md:col-span-3 mb-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"block mb-2 text-sm font-medium",attrs:{"for":"description"}},[_vm._v("Deskripsi Keluhan*")]),_c('div',{staticClass:"relative"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.ticketData.description),expression:"ticketData.description"}],staticClass:"block border border-gray-300 w-full p-3 rounded text-sm bg-white text-gray-700",attrs:{"id":"description"},domProps:{"value":(_vm.ticketData.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ticketData, "description", $event.target.value)}}})]),_c('span',{staticClass:"text-red-600 text-xs"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"mt-6 flex justify-end"},[_c('button',{staticClass:"py-2 px-8 rounded border border-gray-300 mr-2 hover:bg-gray-100",attrs:{"type":"button"},on:{"click":function($event){return _vm.$router.push('/tiket')}}},[_vm._v(" Cancel ")]),_c('button',{staticClass:"py-2 px-8 rounded bg-blue-500 text-white hover:bg-blue-700",attrs:{"disabled":invalid,"type":"submit"}},[_vm._v(" Update ")])])])]}}])})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex items-center justify-between mb-6"},[_c('span',{staticClass:"font-semibold text-xl"},[_vm._v("Edit Tiket")])])}]

export { render, staticRenderFns }